* {
	margin: 0;
}

html, body {
	height: 100%;
	font-family: Verdana, Geneva, sans-serif;
}

body {
	background-position: center center;
}

#body-container {
	position: relative;
	height: auto;
	max-width: 1000px;
	padding: 10px;
	margin: 0 auto;
}

.page-title {
	margin: 50px 0px 10px 0px;
	text-align: center;
}

.img-rescale {
	max-width: 100%;
	height: auto;
	width: auto; /* IE8 */	
}

.img-center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.splash-img {
	width: 100%;
	margin: 0px 0px;
	padding: 0px 0px;
}

.splash-caption {
	font-size: 75%;
	font-style: italic;
	color:#888888;
	margin: 0px;
}
.splash-caption a:link {
	color:#666666;
}
.splash-caption a:visited {
	color:#AAAAAA;
}

.post {
	position:relative;
	margin: 25px 10px 15px 10px;
	padding: 5px 5px 5px 5px;
	height:auto;
	min-height: 100px;
	color:black;
	overflow: hidden;
}

article.post {
	margin: 0 10px 30px 10px;
}

.post aside {
	margin: 0px 0px 5px 0px;
	font-size: 80%;
	color:#888888;
}

.post h1, h2, h3, h4 {
	text-align:left;
}

.post-content {
	display: table;
}

.post-row {
	display: table-row;
}

.post-left {
	display: table-cell;
	width: 100px;
	height: 100px;
	padding: 0px 10px 0px 0px;
	vertical-align: top;
	margin: 0px;
}

.post-thumb {
	border-radius: 2px;
	border: 2px solid #888888;
	padding: 0px;
	margin-top: 0px;
}

.post-right {
	display: table-cell;
	vertical-align: top;
}

.word-count {
	color: #888888;
}

#flex-wrapper {
	display: flex;
}

#flex-left {
	flex: 0 0 65%;
}

#flex-right {
	flex: 1;
}

.toc-title {
	height: 20%
}

.toc-obj {
	height: 10%
}

.fill-all {
	width: 100%;
	height: 100%;
}
